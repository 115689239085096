import React from "react"
import { graphql } from "gatsby"
import PageHeader from "../components/PageHeader"
import urlBuilder from "@sanity/image-url"

import Layout from "../components/layout"

export const query = graphql`
  query($slug: String) {
    sanityWedding(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      date
      weddingImageHero {
        imageUpload {
          asset {
            id
          }
          hotspot {
            x
            y
          }
        }
      }
      weddingImages {
        imageUpload {
          asset {
            id
          }
          hotspot {
            x
            y
          }
        }
        imageDescription
      }
    }
  }
`

function wedding({ data }) {
  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)

  const weddingImages = data.sanityWedding.weddingImages
  const weddingImageHero = data.sanityWedding.weddingImageHero

  return (
    <Layout>
      <PageHeader
        overlay
        backgroundImage={weddingImageHero.imageUpload}
        title={data.sanityWedding.title}
      />
      <div className="sectionContent" style={{ maxWidth: "1000px" }}>
        {weddingImages.map((image) => {
          return (
            <img
              // css={imageStyles}
              sizes="(min-width: 800px) 1000px, 100vw"
              srcSet={[
                urlFor(image.imageUpload.asset.id)
                  .auto("format")
                  .width(2000)
                  // .height(894)
                  .fit("crop")

                  .url() + ` 2000w`,
                urlFor(image.imageUpload.asset.id)
                  .auto("format")
                  .width(800)
                  // .height(357)
                  .fit("crop")

                  .url() + ` 800w`,
              ]}
              src={urlFor(image.imageUpload.asset.id)
                .auto("format")
                .width(800)
                // .height(357)
                .fit("crop")

                .url()}
              alt={image.imageDescription ? image.imageDescription : ""}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default wedding
